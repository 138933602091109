<template>
  <v-btn><slot></slot></v-btn>
</template>

<script setup></script>

<style scoped lang="scss">
button {
  &.primary {
    background: var(--az-primary);
  }
}
</style>
