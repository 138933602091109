export const Routes = {
  Login: "/login",
};

export const Urls = {
  Identity: "https://auth.azurek.hostingasp.pl",
  App: "https://azurek.hostingasp.pl",
  Tasker: "https://tasker.azurek.hostingasp.pl",
  // Identity: "https://localhost:7010",
  // App: "https://localhost:8081",
  // Tasker: "https://localhost:7011",
};
