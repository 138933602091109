<template>
  <v-text-field
    :label="label"
    :value="modelValue"
    @input="handleInput"
    ref="input"
  ></v-text-field>
</template>

<script setup>
import { defineProps, defineEmits, ref, defineExpose } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: false,
  },
  modelValue: {
    type: String,
    required: false,
  },
});

const input = ref(null);
const emit = defineEmits(["update:modelValue"]);
const handleInput = (e) => {
  emit("update:modelValue", e.target.value);
};

defineExpose({ input });

const test = () => {
  console.log("OK");
};
</script>

<style scoped lang="scss"></style>
