import {
  createRouter,
  // createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../modules/account/views/LoginView.vue";
import TestView from "../views/TestView.vue";
import SensorsView from "../views/SensorsView.vue";
import SpeechView from "../views/SpeechView.vue";
import FinderView from "../views/FinderView.vue";
import MainView from "../modules/tasker/views/MainView.vue";
import { Routes, Urls } from "./constants";
import { User } from "@/model/user";
import { useAccountState } from "@/hooks/useAccountState";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: Routes.Login,
    name: "login",
    meta: {
      skipAuth: true,
    },
    component: LoginView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/sensors",
    name: "sensors",
    component: SensorsView,
  },
  {
    path: "/speech",
    name: "speech",
    component: SpeechView,
  },
  {
    path: "/tasker",
    name: "tasker",
    component: MainView,
  },
  {
    path: "/finder",
    name: "finder",
    meta: {
      skipAuth: true,
    },
    component: FinderView,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const { isAuthenticated, isRedirecting, trySignin } = useAccountState();

router.beforeEach(async (to) => {
  console.log("RED", to);
  if (!to.meta.skipAuth && !isAuthenticated.value && !isRedirecting.value) {
    console.log("ASD");
    const succed = await trySignin();
    if (!succed) {
      isRedirecting.value = true;
      window.location.replace(
        `${Urls.Identity}/login?returnUrl=${encodeURI(Urls.App)}`
      );
    }
  }
});

export default router;
