import axios from "axios";
import { reactive } from "vue";

export interface Request<T> {
  data: T | null;
  loading: boolean;
}

export function useTest<T extends object>() {
  const request = reactive<Request<T>>({
    data: null,
    loading: false,
  });

  const fetchData = async (url: string) => {
    request.loading = true;
    const call = await axios.get(url);
    request.data = await call.data;
    request.loading = false;
  };

  return {
    fetchData,
    request,
  };
}
