import { computed, readonly, ref } from "vue";
import {
  createGlobalState,
  // StorageSerializers,
  // useStorage,
} from "@vueuse/core";
import { User } from "@/model/user";
import axios from "axios";
import { Urls } from "@/router/constants";

export const useAccountState = createGlobalState(() => {
  // state
  // const user = useStorage<User | null>("user", null, undefined, {
  //   serializer: StorageSerializers.object,
  // });
  const user = ref<User | null>(null);
  const isRedirecting = ref(false);

  // getters
  const isAuthenticated = computed(() => !!user.value);

  // actions
  function signin(signedInUser: User) {
    user.value = signedInUser;
    isRedirecting.value = true;
    window.location.replace("/");
  }

  const signout = () => {
    user.value = null;
    isRedirecting.value = true;
    window.location.replace(
      `${Urls.Identity}/login?returnUrl=${encodeURI(Urls.App)}`
    );
  };

  const trySignin = async () => {
    const response = await axios.get(`${Urls.Identity}/api/account`);
    const signinUser = response.data;
    user.value = { name: signinUser.userName, email: "" };

    return true;
  };

  return {
    user: readonly(user),
    isAuthenticated,
    isRedirecting,
    signin,
    signout,
    trySignin,
  };
});
