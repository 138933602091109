import { User } from "@/model/user";
import axios from "axios";
import { computed, reactive, readonly } from "vue";

export interface State {
  user?: User;
  count: number;
  items: string[];
}

const state = reactive<State>({
  count: 0,
  items: [],
});

const signin = (user: User): void => {
  state.user = user;
};

const signout = async (): Promise<void> => {
  await axios.get("account/signout");
  state.user = undefined;
  window.location.replace("/");
};

const incrementCount = (): void => {
  state.count++;
};

export interface Store {
  state: State;
  incrementCount: () => void;
  signin: (user: User) => void;
  signout: () => Promise<void>;
}

export default {
  state: readonly(state),
  incrementCount,
  signin,
  signout,
} as Store;

// export function useStore() {
//   const incrementCount = () => {
//     state.count++;
//   };

//   return {
//     state: readonly(state),
//     incrementCount,
//   };
// }
